import React, { Component } from "react";
import ButtonHeader from "../Components/ButtonHeader";
import Footer from "../Components/Footer";
import Copy from "../Components/Copy";

export default class Services extends Component {
  render() {
    return (
      <>
        <ButtonHeader />
        <div className="container mt-header">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-10">
              <h1>Услуги</h1>
              <hr className="hr-line" />
              <p classNam="first-letter">
                — Мы занимаемся изготовлением отливок различной степени
                сложности по модельным оснасткам заказчика. Наши отливки
                соответствуют ГОСТ
              </p>
              <p> — Изготовление модельной оснастки</p>
            </div>
          </div>
        </div>
        <Footer />
        <Copy />
      </>
    );
  }
}
