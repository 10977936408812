import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { YMaps, Map, Placemark } from "react-yandex-maps";
import OnlyButton from "./OnlyButton";

function Footer() {
  return (
    <>
      <Container className="text-center box mb-5">
        <Row className="justify-content-center me">
          <Col>
            <OnlyButton />
          </Col>
        </Row>
      </Container>

      <div>
        <YMaps>
          <Map
            defaultState={{
              center: [59.623633, 33.455868],
              zoom: 15,
              controls: ["zoomControl", "fullscreenControl"],
            }}
            width="100%"
            height={450}
            modules={["control.ZoomControl", "control.FullscreenControl"]}
          >
            <Placemark
              modules={["geoObject.addon.balloon"]}
              defaultGeometry={[59.623633, 33.455868]}
              properties={{
                balloonContentBody:
                  "ООО МашЭнергоПроект - Адрес: 187555, Ленинградская обл, Тихвинский р-н, Тихвин г, Боровая ул, дом № 1, оф.1",
              }}
            />
          </Map>
        </YMaps>
      </div>
    </>
  );
}
export default Footer;
