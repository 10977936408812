import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Copy() {
  return (
    <>
      <Container fluid className="bg-dark" variant="dark">
        <Container>
          <Row md={2}>
            <Col className="text-white mt-2">
              <p className="text-start">© ООО «МашЭнергоПроект»</p>
            </Col>
            <Col className="text-white mt-2">
              <p className="text-end">
                <a href="/">Сайт работает на ReactJS</a>
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default Copy;
