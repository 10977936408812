import React, { Component } from "react";
import ButtonHeader from "../Components/ButtonHeader";
import Footer from "../Components/Footer";
import Copy from "../Components/Copy";

export default class Contacts extends Component {
  render() {
    return (
      <>
        <ButtonHeader />
        <div className="container mt-header">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-10">
              <h1>Контакты</h1>
              <hr className="hr-line" />
              <p></p>
              <ul>
                <li>ООО "МашЭнергоПроект"</li>
                <li>
                  Адрес: 187555, Ленинградская обл, Тихвинский р-н, Тихвин г,
                  Боровая ул, дом № 1, оф.1
                </li>
                <li>
                  E-mail: <a href="mailto:snab@dendor.ru">snab@dendor.ru</a>
                </li>
                <li>Часы работы: ПН-ПТ: 09:00 — 18:00, СБ-ВС — выходные</li>
              </ul>
              <p></p>
            </div>
          </div>
        </div>
        <Footer />
        <Copy />
      </>
    );
  }
}
