import React from "react";
import { Container, Nav, Navbar, Button } from "react-bootstrap";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import Home from "../Pages/Home";
import Services from "../Pages/Services";
import Contacts from "../Pages/Contacts";
import NotFoundPage from "../Pages/NotFoundPage";

function menuCollapsed() {
  const collapsed = "collapsed";
  console.log(collapsed);
  document.getElementsByClassName("navbar-toggler").classList.add("collapsed");
}

function Header() {
  const collapsed = "";
  return (
    <>
      <Router>
        <Navbar className="bg-orange" expand="md" variant="dark">
          <Container className="justify-content-center">
            <Navbar.Toggle
              className={collapsed}
              children="Меню"
              aria-controls="navbarScroll"
            />
            <Navbar.Collapse
              id="navbarScroll"
              className="justify-content-center"
            >
              <Nav
                className=" my-2 my-lg-0"
                style={{ maxHeight: "200px" }}
                navbarScroll
              >
                <Nav.Link onClick={menuCollapsed} as={Link} to="/">
                  О компании
                </Nav.Link>
                <Nav.Link onClick={menuCollapsed} as={Link} to="/services">
                  Услуги
                </Nav.Link>
                <Nav.Link onClick={menuCollapsed} as={Link} to="/contacts">
                  Контакты
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="*" status={404} element={<NotFoundPage />} />
        </Routes>
      </Router>
    </>
  );
}

export default Header;
