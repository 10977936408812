import React from "react";
import { Container } from "react-bootstrap";
import OnlyButton from "./OnlyButton";

function ButtonHeader() {
  return (
    <>
      <Container className="d-flex justify-content-end send gx-5">
        <OnlyButton />
      </Container>
    </>
  );
}

export default ButtonHeader;
