import React from "react";
import { Button } from "react-bootstrap";
import ModalWindow from "./ModalWindow";

function OnlyButton() {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <Button variant="warning" size="lg" onClick={() => setModalShow(true)}>
        Оставить заявку
      </Button>

      <ModalWindow show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}

export default OnlyButton;
