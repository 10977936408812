import React, { Component } from "react";
import ButtonHeader from "../Components/ButtonHeader";
import Footer from "../Components/Footer";
import Copy from "../Components/Copy";

export default class NotFoundPage extends Component {
  render() {
    return (
      <>
        <ButtonHeader />
        <div className="container mt-header">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-10">
              <h1>404</h1>
              <hr className="hr-line" />
              <p classNam="first-letter">
                Страница, которую вы ищите не существует на сайте.
              </p>
            </div>
          </div>
        </div>
        <Footer />
        <Copy />
      </>
    );
  }
}
