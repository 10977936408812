import React, { Component } from "react";
import ButtonHeader from "../Components/ButtonHeader";
import Footer from "../Components/Footer";
import Copy from "../Components/Copy";
import InfoJpg from "../img/info.jpg";
import Icon1 from "../img/icon1.jpg";
import Icon2 from "../img/icon2.jpg";
import Icon3 from "../img/icon3.jpg";
import Icon4 from "../img/icon4.jpg";
import Icon5 from "../img/icon5.jpg";

export default class Home extends Component {
  render() {
    return (
      <>
        <ButtonHeader />
        <div className="container mt-header">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-10">
              <h1>ООО "МашЭнергоПроект"</h1>
              <hr className="hr-line" />
              <p className="first-letter">
                Основное направление деятельности компании - литейное
                производство. Мы применяем технологию литья чугуна в
                холодно-твердеющие смеси (ХТС), которая позволяет отливать
                типоразмеры от DN32 ДО DN1400, сохраняя высокую точность форм и
                стержней. Мы готовы изготовить чугунные отливки по
                предоставленным вами модельным оснасткам. Наше предприятие
                сотрудничает с крупнейшими производителями трубопроводной
                арматуры Северо-Западного федерального округа России, одним из
                ключевых заказчиков в последние годы для нас является АО
                "ЭНЕРГИЯ" (ТПА DENDOR).
              </p>
              <div className="text-center">
                <img
                  className="img-fluid mx-auto d-block"
                  src={InfoJpg}
                  alt="Производство МЭП"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-10">
              <h3 className="orange box">Мы располагаем:</h3>
              <div className="row">
                <div className="col-md-6 text-center">
                  <img src={Icon1} />
                  <p>собственными литейными цехами</p>
                </div>
                <div className="col-md-6 text-center">
                  <img src={Icon2} />
                  <p>автопарком</p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-10">
              <h3 className="orange box">Мы обеспечиваем:</h3>
              <div className="row">
                <div className="col-md-4 text-center">
                  <img src={Icon3} />
                  <p>высококачественное литье</p>
                </div>
                <div className="col-md-4 text-center">
                  <img src={Icon4} />
                  <p>соблюдение сроков исполнения</p>
                </div>
                <div className="col-md-4 text-center">
                  <img src={Icon5} />
                  <p>доставку готовых отливок</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <Copy />
      </>
    );
  }
}
