import React from "react";
import { Modal, Button } from "react-bootstrap";

function ModalWindow(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Оставить заявку
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <iframe
          className="formMob"
          src="https://forms.yandex.ru/cloud/6212978306fcba93a12cc408/?iframe=1"
          frameborder="0"
          name="ya-form-6212978306fcba93a12cc408"
        ></iframe>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Закрыть</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalWindow;
